import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "components/layout"
import SEO from "../../components/seo.js"
import DirectoryCategories from "../../components/content/categories"
import GridIcons from "../../components/content/gridicons"

const BusinessPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query ProviderPageQuery {
        site {
          siteMetadata {
            title
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="For Business and Service Providers" />

          <div className={"page-header"}>
            <div className="container">
              {/* <img src={SlackUVisionImage}/> */}
              {/* <img data-src="holder.js/500x100?theme=gray" /> */}
              <h2>{data.site.siteMetadata.title} &amp; Your Tech Company</h2>
              <p>
                Your customers need better intelligence on your services and
                solutions {/* <br/> */}
                &amp; your teams need to build more trust with customers.
              </p>
              <div className="col-xs-auto">
                <img
                  alt={process.env.REACT_APP_NAME} 
                  src="/images/b2bscreenshot1.png"
                  className="center-block  img-responsive mx-auto"
                />
              </div>{" "}
            </div>
          </div>

          <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Request your Report</h2>
                <p>
                  Request a report for your technology company to evaluate your
                  standards and to ensure your customers trust your standards.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to="/request-assessment"
                  // target={"_blank"}
                >
                  Request Report
                </Link>
              </div>
            </div>
          </div>

          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
                  <h3>
                    Highlight your organizations privacy, policy &amp; cyber
                    security standards.
                  </h3>
                  {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
                  <p>
                    By proactively monitoring, disclosing, annotating and
                    analyzing your own organization and your vendor's, you can
                    ensure a better future for your customers, team and bottom
                    line.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-bag" />
                  <h5>Build Trust</h5>
                  <p>
                    Show how your organization is working towards ensuring data
                    privacy that real people, customers &amp; regulators care
                    about. Disclose and showcase personal data privacy
                    parameters related to your app or service's privacy policies
                    as well as compliance 24/7.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-graph" />
                  <h5>Highlight Standards</h5>
                  <p>
                    Based on the most modern web standards, devices, platforms,
                    operating systems and eco systems from PC, mobile, IoT, AI,
                    XR &amp; more, highlight how your company handles data
                    protection and trust.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-list-thumbnails" />
                  <h5>Enable Transparency</h5>
                  <p>
                    We provide your end users and customers useful and helpful
                    data and information on your solutions and services to help
                    them instantly evaluate personal data policies and data
                    privacy standards of your team and company.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  <h3>
                    Does your organization collect personal data or care about
                    customer data?
                  </h3>

                  <p>
                    As the largest, open database of privacy ratings and reports
                    in the world, our business is making high-quality, official
                    privacy data openly available to consumers and customers.
                    Privacy and InfoSec Data that can be trusted, accessed,
                    analysed, monitored and reviewed when and how it’s needed.
                  </p>
                </div>
              </div>

              <GridIcons />
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <Link to="/about/why" className="btn btn-primary  btn-sm">
                  Learn More about Why UVision Exists?
                </Link>
              </div>
            </div>
          </section>

          <section className="features features-3">
            <div className={"bg-color2"}>
              <div className={"container"}>
                <div className={"integration-guid"}>
                  <div className={"title"}>
                    <h2>
                      We provide data to your customers and data on your vendors
                      and many more...
                    </h2>
                    <br />
                    <br />
                  </div>
                  <DirectoryCategories />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>

          {/* 
    <div className={"bg-color"}>
      <div className={"container"}>
        <div className={"integration-guid"}>
          <div className={"title"}>
            <h2>How to use UVision for Business</h2>
          </div>

          <div className={"content"}>
            <h4>Overview</h4>
            <p>
              As a service provider or business customer, you can use the site
              for free just like end users can but there's additional features
              and benefits for you.
            </p>
            <h4>Step 1</h4>
            <p>
              You can also search for any vendor, product, site, app, service,
              solution or company that's in our database.
            </p>
            

            <h4>Step 2</h4>
            <p>
              You can then view the profile of the company in question and
              relevant information, tools and data at your finger tips.
            </p>
            
            <h4>Step 3</h4>
            <p>
              You can also view more in depth business-only information if you
              or your team members create an account and save the profile in
              your dashboard in your account for future reference and to monitor
              changes to profiles.
            </p>
            
          </div>
        </div>
      </div>
    </div> */}

          <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with{" "}
                  {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )}
  />
)

export default BusinessPage
